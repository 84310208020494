@font-face {
  font-family: GilroyL;
  src: url(Gilroy-Light.otf);
}
@font-face {
  font-family: GilroyN;
  src: url(Gilroy-ExtraBold.otf);
}



#root {
  margin-top: -100px;
  text-align: center;
    width: 72%;
}

